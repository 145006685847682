/**
 * JS component for actions on Localized Header Components
 */
export function LocalizedHeaderComponent() {
  const GEOTARGET_URL = 'https://us-central1-stdcheck-wp-theme.cloudfunctions.net/geotargets';
  const LOADING_SPINNER_CLASS = 'is-loading-inline';
  const IID = 'iid';
  const PID = 'pid';

  let searchParams = new URLSearchParams(window.location.search);

  /**
   * Starts the logic for localized header function
   */
  function localizedHeader() {
    if (!searchParams.get(IID) && !searchParams.get(PID)) {
      removeLoadingSpinner();

      return;
    }

    displayGeotargetNameInHeading(searchParams.get(IID) ? IID : PID);
  }

  /**
   * Fetches and displays the geotargeting name if it exists.
   *
   * A bad response will result in the default title being displayed.
   *
   * @param {string} key either iid or pid
   */
  function displayGeotargetNameInHeading(key) {
    fetchGeotargetName(key)
      .then((response) => displayLocationName(response))
      .always(() => removeLoadingSpinner());
  }

  /**
   * Executes the get location request.
   *
   * @param {string} key either iid or pid
   *
   * @returns {$.jqXHR}
   */
  function fetchGeotargetName(key) {
    return $.get(GEOTARGET_URL + `?${key}=${searchParams.get(key)}`);
  }

  /**
   * Replace the Primary section title showing user location based on either iid or pid
   *
   * @param {string} locationName Location name retrieved from STDCheck api.
   */
  function displayLocationName(locationName) {
    if (!locationName || typeof locationName == 'undefined') {
      return;
    }

    $("section[data-qa-id='Primary Index Section'] h1 span").html(locationName + ' STD Testing');
  }

  /**
   * Removes the loading spinner that is displayed while the geotarget is fetched.
   */
  function removeLoadingSpinner() {
    $('#page-title').removeClass(LOADING_SPINNER_CLASS);
  }

  localizedHeader();
}

new LocalizedHeaderComponent();
